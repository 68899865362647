<template>
    <div 
        :style="color != undefined ? `background: ${color}` : null"
        class="col"
        :class="{
            'col--offset-small': offsetSmall,
            'col--flex-start': flexStart,
            'col--width-large': large,
            'col--width-small': small,
            'col--width-half': half,
            'col--full-mobile': mobile,
            'col--hide': mobile,
            'col--border-right': borderRight,
            'col--width-xsmall': xsmall,
            'col--width-full': full,
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
        },

        offsetSmall: {
            type: Boolean,
            default: false
        },

        flexStart: {
            type: Boolean,
            default: false
        },

        large: {
            type: Boolean,
            default: false
        },

        small: {
            type: Boolean,
            default: false
        },

        half: {
            type: Boolean,
            default: false
        },

        mobile: {
            type: Boolean,
            default: false
        },

        hide: {
            type: Boolean,
            default: false
        },

        borderRight: {
            type: Boolean,
            default: false
        },

        xsmall: {
            type: Boolean,
            default: false
        },

        full: {
            type: Boolean,
            default: false
        }

    }
}
</script>

<style lang="scss" scoped>
.col {
    position: relative;
    // flex-grow: 1;
    // flex-shrink: 1;
    // flex-basis: 0;
    align-self: center;
    padding: 0 rem(20);

    &--width-small {
        width: 30%;
    }

    &--width-xsmall {
        width: 15%;
    }

    &--width-large {
        width: 65%;
    }

    &--width-half {
        width: 50%;
    }

     &--width-full {
        width: 100%;
    }

    &--hide {
        @media(max-width: $mobile-bp) {
            //display: none;
        }
    }

    &--full-mobile {
        @media(max-width: $mobile-bp) {
            width: 100%;
        }
    }

    &--border-right {
        border-left:1px solid $primary;

        @media(max-width: $mobile-bp) {
            border-left: 0;
        }
        
    }

    &--offset-small {
        padding: 0 rem(100);

         @media(max-width: 1200px) {
            padding: 0 rem(10);
        }

        @media(max-width: $mobile-bp) {
            width: calc(100% - 20px);
        }
    }

    &--flex-start {
        align-self: flex-start;
    }

    @media(max-width: $mobile-bp) {
        padding: 0;
    }
}
</style>