var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col",class:{
        'col--offset-small': _vm.offsetSmall,
        'col--flex-start': _vm.flexStart,
        'col--width-large': _vm.large,
        'col--width-small': _vm.small,
        'col--width-half': _vm.half,
        'col--full-mobile': _vm.mobile,
        'col--hide': _vm.mobile,
        'col--border-right': _vm.borderRight,
        'col--width-xsmall': _vm.xsmall,
        'col--width-full': _vm.full,
    },style:(_vm.color != undefined ? ("background: " + _vm.color) : null)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }